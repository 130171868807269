<template>

    <div class="fixed bg-white top-0 left-0 blurred-overlay h-full w-full flex flex-col items-center justify-center"
        style="z-index:9999">

        <div class="na-filter-box" style="max-width:460px;">

            <div class="flex flex-row justify-between nan-filter-row" >
                <div class="flex flex-row items-center">
                    <img :style="{
                        'width': '19px',
                        'height': '18px',
                        'background-color': '#000000',
                        'mask': 'url(\'' + require(`@/assets/img/icons/filter.svg`) + '\')',
                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/filter.svg`) + '\')'
                    }" class="" />
                    &nbsp;&nbsp;
                    <p class="na-filter-heading">Filtra</p>
                </div>


                <button v-on:click="_toggleFilter(false)" type="button" class="">
                    <img :style="{
                        'width': '13px',
                        'height': '13px',
                        'background-color': '#3D3D3D',
                        'mask': 'url(\'' + require(`@/assets/img/icons/close-popup.svg`) + '\')',
                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/close-popup.svg`) + '\')'
                    }" class="" />
                </button>
            </div>

            <div class="flex flex-row nan-filter-row lg:hidden block">
                
                <div class="flex lg:flex-row flex-col lg:items-center lg:justify-between flex-1">
                    <p class="na-filter-label">Città</p>
                    <div key="refCity" @click="toggleHoverClass('refCity')"
                        ref="refCity"
                        class="na-dropdown-pill na-has-label has-options relative flex flex-row justify-between items-center">

                        <p class="nan-filter-dropdown">{{ filterParams.city }}</p>
                        <div>
                            <img :style="{
                                'width': '9px',
                                'height': '9px',
                                'background-color': '#000',
                                'mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')',
                                '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')'
                            }" class="" />
                        </div>

                        <ul class="na-dropdown-pill-options absolute top-10 left-0">
                            <li v-for="(item, key) in cities" :key="key"
                                @click="setCity(item);">{{ item }}</li>
                        </ul>
                    </div>
                </div>

                <div class="flex lg:flex-row flex-col lg:items-center lg:justify-between flex-1">
                    <p class="na-filter-label">Zona</p>
                    <div key="refZone" @click="toggleHoverClass('refZone')"
                        ref="refZone"
                        class="na-dropdown-pill na-has-label has-options relative flex flex-row justify-between items-center">

                        <p class="nan-filter-dropdown">{{ filterParams.zone }}</p>
                        <div>
                            <img :style="{
                                'width': '9px',
                                'height': '9px',
                                'background-color': '#000',
                                'mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')',
                                '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')'
                            }" class="" />
                        </div>

                        <ul class="na-dropdown-pill-options absolute top-10 left-0">
                            <li v-for="(item, key) in zones" :key="key"
                                @click="setZone(item); ">
                                {{ item }}</li>
                        </ul>
                    </div>
                </div>
                
            </div>

            <div class="flex flex-row nan-filter-row lg:hidden block">
                
                <div class="flex lg:flex-row flex-col lg:items-center lg:justify-between flex-1">
                    <p class="na-filter-label">Tipologia</p>
                    <div  key="refType" @click="toggleHoverClass('refType')"
                        ref="refType"
                        class="na-dropdown-pill na-has-label has-options relative flex flex-row justify-between items-center">

                        <p class="nan-filter-dropdown">{{ filterParams.type }}</p>
                        <div>
                            <img :style="{
                                'width': '9px',
                                'height': '9px',
                                'background-color': '#000',
                                'mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')',
                                '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')'
                            }" class="" />
                        </div>

                        <ul class="na-dropdown-pill-options alt absolute top-10 left-0">
                            <li v-for="(item, key) in typology" :key="key"
                                @click="setType(item);">
                                {{ item }}</li>
                        </ul>
                    </div>
                </div>

                <div class="flex lg:flex-row flex-col lg:items-center lg:justify-between flex-1">
                    &nbsp;
                </div>
                
            </div>

            <div class="flex flex-row nan-filter-row">

                <div class="flex lg:flex-row flex-col lg:items-center lg:justify-between flex-1">
                    <p class="na-filter-label">Locali</p>
                    <div @click="toggleHoverClass('refRooms')"
                        ref="refRooms"
                        class="na-dropdown-pill na-has-label has-options relative flex flex-row justify-between items-center">

                        <p class="nan-filter-dropdown">{{ rooms }}</p>
                        <div>
                            <img :style="{
                                'width': '9px',
                                'height': '9px',
                                'background-color': '#000',
                                'mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')',
                                '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')'
                            }" class="" />
                        </div>

                        <ul class="na-dropdown-pill-options absolute top-10 left-0">
                            <li key="rooms-any" @click="setRooms('Tutti'); removeHoverClass('refRooms');">Tutti</li>
                            <li v-for="index in 10" :key="index"
                                @click="setRooms(index);">
                                {{ index }}</li>
                        </ul>
                    </div>
                </div>

                <div class="flex lg:flex-row flex-col lg:items-center lg:justify-between flex-1">
                    <p class="na-filter-label">Bagni</p>
                    <div @click="toggleHoverClass('refBathrooms')"
                        ref="refBathrooms"
                        class="na-dropdown-pill na-has-label has-options relative flex flex-row justify-between items-center">
                        <p class="nan-filter-dropdown">{{ bathrooms }}</p>
                        <div>
                            <img :style="{
                                'width': '9px',
                                'height': '9px',
                                'background-color': '#000',
                                'mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')',
                                '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')'
                            }" class="" />
                        </div>

                        <ul class="na-dropdown-pill-options hidden absolute top-10 left-0">
                            <li key="bathrooms-any" @click="setBathrooms('Tutti'); removeHoverClass('refBathrooms');">Tutti</li>
                            <li v-for="index in 10" :key="index"
                                @click="setBathrooms(index);">{{ index }}</li>
                        </ul>
                    </div>
                </div>
                <div class="flex lg:flex-row flex-col lg:items-center lg:justify-between flex-1">&nbsp;</div>

            </div>

            <div class="nan-filter-row">
                <div class="flex flex-row justify-between items-center">
                    <p class="na-filter-label">Prezzo</p>
                    <p class="na-filter-price-range">{{ parseInt(price.value[0]).toLocaleString() }}€ - {{ parseInt(price.value[1]).toLocaleString() }}€</p>
                </div>

                <div class="relative">
                    <div class="na-vueform-slider-marker absolute left-0 top-0 start"></div>
                    <div style="margin-top: 15px;">
                        <Slider v-model="price.value" :step="price.step" :min="price.min" :max="price.max"
                            :tooltips="false" />
                    </div>
                    <div class="na-vueform-slider-marker absolute right-0 top-0 end"></div>
                </div>

            </div>

            <div class="flex flex-col nan-filter-row">

                <p class="na-filter-label">Metri quadri</p>

                <div class="flex flex-row justify-between" style="margin-top: 15px;">
                    <div class="flex flex-row relative">
                        <input placeholder="Min" v-model="areaMin" type="text" class="na-filter-input">
                        <div class="absolute right-2 z-1 na-filter-input-suffix">mq</div>
                    </div>

                    <div class="flex flex-row justify-end relative">
                        <input placeholder="Min" v-model="areaMax" type="text" class="na-filter-input">
                        <div class="absolute right-2 z-1 na-filter-input-suffix">mq</div>
                    </div>
                </div>


            </div>

            <div class="flex flex-row lg:justify-end justify-between nan-filter-row border-0">

                <button @click="resetForm" type="button" class="na-filter-button white">
                    <span class="mx-auto">Reset</span>
                </button>

                <button @click="_setFilterParams" type="button" class="na-filter-button primary">
                    <span class="mx-auto">Applica</span>
                </button>

            </div>

        </div>

    </div>

</template>

<script>
import Close from "vue-material-design-icons/Close.vue";
import Slider from '@vueform/slider'


export default {
    components: {
        Close,
        Slider,
    },
    props: {
        toggleFilter: {
            type: Function
        },
        setFilterParams: {
            type: Function
        },
        filterParams: {
            type: Object,
            required: true
        },
        cities: {
            type: Array,
            required: true
        },
        zones: {
            type: Array,
            required: true
        },
        typology: {
            type: Array,
            required: true
        }
    },
    created() { 
        
    },
    mounted() { 
        
    },
    unmounted() { },
    data() {
        return {
            city: this.filterParams.city,
            zone: this.filterParams.zone,
            type: this.filterParams.type,
            rooms: this.filterParams.rooms,
            bathrooms: this.filterParams.bathrooms,
            areaMin: this.filterParams.area.areaMin,
            areaMax: this.filterParams.area.areaMax,
            price: {
                value: [this.filterParams.price[0], this.filterParams.price[1]],
                min: 0,
                max: 1000000,
                step: 10,
            },
            
        }
    },
    
    methods: {
        toggleHoverClass(ref) {
            if( this.$refs[ref].classList.contains('hovered') ) {
                this.$refs[ref].classList.remove('hovered');
            } else {
                this.$refs[ref].classList.add('hovered')
            }
            
        },
        addHoverClass(ref){
            console.log('open', ref );

            this.$refs[ref].classList.add('hovered')
            
        },
        removeHoverClass(ref) {
            console.log('close', ref );
            this.$refs[ref].classList.remove('hovered');
        },
        filterProperties(){

        },
        _toggleFilter(status) {
            console.log('testing');
            this.$emit("toggleFilter", status);
        },
        setBathrooms(index) {
            this.bathrooms = index
        },
        setRooms(index) {
            this.rooms = index
        },
        resetForm(){
            this.filterParams.city = this.cities[0];
            this.filterParams.zone = this.zones[0];
            this.filterParams.type = this.types[0];
            this.bathrooms = 'Tutti';
            this.rooms = 'Tutti';
            this.areaMin = 1;
            this.areaMax = 200;
            this.price.value[0] = 1;
            this.price.value[1] = 1000000;

            this.$emit("setFilterParams", {
                city: this.city,
                zone: this.zone,
                type: this.type,
                rooms: this.rooms,
                bathrooms: this.bathrooms,
                price: this.price.value, 
                area: {
                    areaMax: this.areaMax,
                    areaMin: this.areaMin
                }
            });
        },
        _setFilterParams(){
            
            this.$emit("setFilterParams", {
                city: this.filterParams.city,
                zone: this.filterParams.zone,
                type: this.filterParams.type,
                rooms: this.rooms,
                bathrooms: this.bathrooms,
                price: this.price.value, 
                area: {
                    areaMax: this.areaMax,
                    areaMin: this.areaMin
                }
            });

            this.$emit("toggleFilter", false);
        },
        setCity(item) {
            this.filterParams.city = item;
        },
        setZone(item){
            this.filterParams.zone = item;
        },
        setType(item) {
            this.filterParams.type = item;
        },

    }


}
</script>
<!-- <style src="@vueform/slider/themes/default.css"></style> -->