<template>  
  <div id="app">
    <router-view />
  </div>
</template> 
<style>

/* @font-face {
  font-family: "Visby";
  src: local("Visby"),
  url(assets/font/visby-org/VisbyBold-Italic.woff) format("woff"),
  url(assets/font/visby-org/VisbyBold.woff) format("woff"),
  url(assets/font/visby-org/VisbyExtrabold-Italic.woff) format("woff"),
  url(assets/font/visby-org/VisbyExtrabold.woff) format("woff"),
  url(assets/font/visby-org/VisbyHeavy-Italic.woff) format("woff"),
  url(assets/font/visby-org/VisbyHeavy.woff) format("woff"),
  url(assets/font/visby-org/VisbyItalic.woff) format("woff"),
  url(assets/font/visby-org/VisbyLight-Italic.woff) format("woff"),
  url(assets/font/visby-org/VisbyLight.woff) format("woff"),
  url(assets/font/visby-org/VisbyMedium-Italic.woff) format("woff"),
  url(assets/font/visby-org/VisbyMedium.woff) format("woff"),
  url(assets/font/visby-org/VisbyRegular.woff) format("woff"),
  url(assets/font/visby-org/VisbySemibold-Italic.woff) format("woff"),
  url(assets/font/visby-org/VisbySemibold.woff) format("woff"),
  url(assets/font/visby-org/VisbyThin-Italic.woff) format("woff"),
  url(assets/font/visby-org/VisbyThin.woff) format("woff")
   
    url(assets/font/Newarc-Medium.ttf) format("truetype"),
   url(assets/font/Newarc-MediumOblique.ttf) format("truetype"),
   url(assets/font/Newarc-Thin.ttf) format("truetype"),
   url(assets/font/Newarc-ThinOblique.ttf) format("truetype"),
   url(assets/font/Newarc-Light.ttf) format("truetype"),
   url(assets/font/Newarc-LightOblique.ttf) format("truetype"),
   url(assets/font/Newarc-Heavy.ttf) format("truetype"),
   url(assets/font/Newarc-HeavyOblique.ttf) format("truetype"),
   url(assets/font/Newarc-ExtraBold.ttf) format("truetype"),
   url(assets/font/Newarc-ExtraBoldOblique.ttf) format("truetype"),
   url(assets/font/Newarc-DemiBold.ttf) format("truetype"),
   url(assets/font/Newarc-DemiBoldOblique.ttf) format("truetype"),
   url(assets/font/Newarc-Bold.ttf) format("truetype"),
   url(assets/font/Newarc-BoldOblique.ttf) format("truetype"); 
}  */

/* @font-face {
  font-family: "Visby Light";
  src: local("Visby Light"),
  url(assets/font/visby-org/VisbyLight.woff) format("woff"),
}
@font-face {
  font-family: "Visby Normal";
  src: local("Visby Normal"),
  url(assets/font/visby-org/VisbyRegular.woff) format("woff"),
}
@font-face {
  font-family: "Visby Semibold";
  src: local("Visby Semibold"),
  url(assets/font/visby-org/VisbySemibold.woff) format("woff"),
}
@font-face {
  font-family: "Visby Bold";
  src: local("Visby Bold"),
  url(assets/font/visby-org/VisbyBold.woff) format("woff"),
}
@font-face {
  font-family: "Visby Extrabold";
  src: local("Visby Extrabold"),
  url(assets/font/visby-org/VisbyExtrabold.woff) format("woff"),
} */

/* @font-face {
font-family: 'Visby Regular';
font-style: normal;
font-weight: normal;
src: local('Visby Regular'), url('assets/font/visby-org/VisbyRegular.woff') format('woff');
}


@font-face {
font-family: 'Visby Bold Italic';
font-style: normal;
font-weight: normal;
src: local('Visby Bold Italic'), url('assets/font/visby-org/VisbyBold Italic.woff') format('woff');
}


@font-face {
font-family: 'Visby Bold';
font-style: normal;
font-weight: normal;
src: local('Visby Bold'), url('assets/font/visby-org/VisbyBold.woff') format('woff');
}


@font-face {
font-family: 'Visby Extrabold Italic';
font-style: normal;
font-weight: normal;
src: local('Visby Extrabold Italic'), url('assets/font/visby-org/VisbyExtrabold Italic.woff') format('woff');
}


@font-face {
font-family: 'Visby Extrabold';
font-style: normal;
font-weight: normal;
src: local('Visby Extrabold'), url('assets/font/visby-org/VisbyExtrabold.woff') format('woff');
}


@font-face {
font-family: 'Visby Heavy Italic';
font-style: normal;
font-weight: normal;
src: local('Visby Heavy Italic'), url('assets/font/visby-org/VisbyHeavy Italic.woff') format('woff');
}


@font-face {
font-family: 'Visby Heavy';
font-style: normal;
font-weight: normal;
src: local('Visby Heavy'), url('assets/font/visby-org/VisbyHeavy.woff') format('woff');
}


@font-face {
font-family: 'Visby Italic';
font-style: normal;
font-weight: normal;
src: local('Visby Italic'), url('assets/font/visby-org/VisbyItalic.woff') format('woff');
}


@font-face {
font-family: 'Visby Light Italic';
font-style: normal;
font-weight: normal;
src: local('Visby Light Italic'), url('assets/font/visby-org/VisbyLight Italic.woff') format('woff');
}


@font-face {
font-family: 'Visby Light';
font-style: normal;
font-weight: normal;
src: local('Visby Light'), url('assets/font/visby-org/VisbyLight.woff') format('woff');
}


@font-face {
font-family: 'Visby Medium Italic';
font-style: normal;
font-weight: normal;
src: local('Visby Medium Italic'), url('assets/font/visby-org/VisbyMedium Italic.woff') format('woff');
}


@font-face {
font-family: 'Visby Medium';
font-style: normal;
font-weight: normal;
src: local('Visby Medium'), url('assets/font/visby-org/VisbyMedium.woff') format('woff');
}


@font-face {
font-family: 'Visby Semibold Italic';
font-style: normal;
font-weight: normal;
src: local('Visby Semibold Italic'), url('assets/font/visby-org/VisbySemibold Italic.woff') format('woff');
}


@font-face {
font-family: 'Visby Semibold';
font-style: normal;
font-weight: normal;
src: local('Visby Semibold'), url('assets/font/visby-org/VisbySemibold.woff') format('woff');
}


@font-face {
font-family: 'Visby Thin Italic';
font-style: normal;
font-weight: normal;
src: local('Visby Thin Italic'), url('assets/font/visby-org/VisbyThin Italic.woff') format('woff');
}


@font-face {
font-family: 'Visby Thin';
font-style: normal;
font-weight: normal;
src: local('Visby Thin'), url('assets/font/visby-org/VisbyThin.woff') format('woff');
} */

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&display=swap');


@import './assets/styles/-tailwind.css';
@import './assets/styles/utils.css';
@import './assets/styles/base.css';
@import './assets/styles/property-pages.css';
@import './assets/styles/vueform-slider.css';
@import './assets/styles/single-property.css';


</style>

