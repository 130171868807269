<template>
    <div class="flex flex-row ">
        <div @click="toggleHoverClass('refCity')" @mouseleave="removeHoverClass('refCity')" ref="refCity"
            class="na-dropdown-pill cursor-pointer na-has-label ternary low-height has-options relative flex flex-row justify-between items-center">

            <!-- <label class="absolute">CITTÀ</label> -->
            <p title="{{ filterParams.city }}">{{ filterParams.city }}</p>

            <div>
                <img :style="{
                    'width': '9px',
                    'height': '9px',
                    'background-color': '#686868',
                    'mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')',
                    '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')'
                }" class="" />
            </div>

            <ul class="na-dropdown-pill-options hidden absolute top-7 left-0">
                <li v-for="(item, key) in cities" @click="setCity(item);" :key="key">
                    {{ item }}
                </li>
            </ul>
        </div>

        <div @click="toggleHoverClass('refZone')"  @mouseleave="removeHoverClass('refZone')" ref="refZone"
            class="na-dropdown-pill azone-list cursor-pointer na-has-label ternary wide low-height has-options relative flex flex-row justify-between items-center">

            <!-- <label class="absolute">ZONA</label> -->
            <p title="{{ filterParams.zone }}">{{ filterParams.zone }}</p>

            <div>
                <img :style="{
                    'width': '9px',
                    'height': '9px',
                    'background-color': '#686868',
                    'mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')',
                    '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')'
                }" class="" />
            </div>

            <ul class="na-dropdown-pill-options hidden absolute top-7 left-0">
                <li v-for="(item, key) in zones" @click="setZone(item);" :key="key">
                    {{ item }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        toggleMap: {
            type: Function
        },
        toggleFilter: {
            type: Function
        },
        filterParams: {
            type: Object,
            required: true
            
        },
        setFilterParams: {
            type: Function
        },
        cities: {
            type: Array,
            required: true
        },
        zones: {
            type: Array,
            required: true
        },
        typology: {
            type: Array,
            required: true
        }

    },
    created() {},
    beforeMount(){},
    mounted() {
        this.filterParams.city = this.cities[0];
        this.filterParams.zone = this.zones[0];
        this.filterParams.type = this.typology[0];
        console.log('cities', this.cities);
    },
    unmounted() {},
    components() {},
    methods: {
        _toggleMap(){
            this.$emit("toggleMap");
        },
        _toggleFilter(status) {
            this.$emit("toggleFilter", status);
        },
        toggleHoverClass(ref) {
            if( this.$refs[ref].classList.contains('hovered') ) {
                console.log('removed');
                this.$refs[ref].classList.remove('hovered');
            } else {
                console.log('added');
                this.$refs[ref].classList.add('hovered')
            }
            
        },
        removeHoverClass(ref) {
            console.log(this.$refs[ref]);
            this.$refs[ref].classList.remove('hovered');
        },
        setCity(item) {
            this.filterParams.city = item;
            this._setFilterParams();

        },
        setZone(item){
            this.filterParams.zone = item;
            this._setFilterParams();
        },
        setType(item) {
            this.filterParams.type = item;
            this._setFilterParams();
        },
        _setFilterParams(){
            this.$emit("setFilterParams", {
                city: this.filterParams.city,
                zone: this.filterParams.zone,
                type: this.filterParams.type,
                rooms: this.filterParams.rooms,
                bathrooms: this.filterParams.bathrooms,
                price: this.filterParams.price, 
                area: this.filterParams.area,
            });
        }
    },
    data() {
        return {
            showMap: true,
            // cities: ['Torino'],
            // zones: ["Tutte le zone", "Campidoglio", "Colle della Maddalena", "San Secondo", "Pozzo Strada", "Crocetta", "San Salvario - Baretti", "Sassi", "Parella", "Lingotto", "Piazza Solferino", "Quadrilatero Romano", "Cavoretto", "Madonna del Pilone", "Superga", "Via della Rocca", "Santa Rita", "Via Roma", "Cit Turin", "San Donato", "Mirafiori Nord", "Gran Madre - Crimea", "San Salvario - Dante", "San Paolo", "Cenisia", "Nizza Millefonti", "Regio Parco", "Cittadella", "Città Giardino", "Vanchiglia", "Vanchiglietta", "Centro Europa", "Giardini Reali", "Italia 61"],
            // typology: ['Tutte', 'Appartamento', 'Villa', 'Casa indipendente', 'Loft', 'Attico', 'Mansarda'],
        }
    }
}

</script>